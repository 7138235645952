@import '~libs/react-mpk/styles/var';

.select-company{

}

$card-width: 200px;
.company-card{
  width: $card-width;
  cursor: pointer;
  .company-card-logo{
    width: $card-width;
    height: $card-width;
    border-radius: 0;
    background: #3a3a3a;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .md-icon{
      font-size: 92px;
      color: $mpk-action-footer-color;
    }
  }
  .company-card-info{
    border-top: thin solid $mpk-action-footer-color;
  }
}