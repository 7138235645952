.company{
  background: transparent;
  .logo{
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background: #d6d0c9;
    position: relative;
    border: 2px solid white;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 48px;
      z-index: 1;
      position: relative;
    }
    .default-img{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
    }
  }
}