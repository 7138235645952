.landing-product{
  max-width: 640px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding-bottom: 64px;
  .logo{
    text-align: center;
    padding: 24px 0;
    img{
      width: 240px;
      height: 240px;
      object-fit: contain;
      padding: 16px;
    }
  }
  .mpk-body p{
    color: #888;
  }
  .md-dialog-footer{
    bottom: 0;
    position: absolute;
    width: 100%;
  }
}