@import 'globals';
@import '~libs/react-mpk/styles/mpk';

$max-media-width:1024px;
$max-side-width:240px;

.App {
  width: 100%;
  height: 100%;
}

.center-container{
  width: $max-media-width;
  margin:0 auto;
}

.side-container{
  width: $max-side-width;
}

.form-container{
  margin:0 auto;
  &.md {max-width: 800px;}
  &.sm {max-width: 480px;}
}

.text-editor{
  border:thin solid $mpk-border-color-dark;
  padding:0 $mpk-padding-N;
}

.md-divider--text-field{
  &:after {
    height: 1px !important;
  }
}

.tabs-details{
  border-bottom:thin solid $mpk-border-color-dark;
  background: #f8f8f8;
  padding-left: 0px !important;
}
.md-tab{
  min-width: 0px !important;
}
.md-tab--inactive{
  color:rgba(0,0,0,.52) !important;
}
.md-edit-dialog__label{
  width:auto !important;
}
.full-form{
  width: 100% !important;
}

.min-width-S{min-width: 92px;}
.min-width-NS{min-width: 120px;}
.min-width-N{min-width: 160px;}
.min-width-L{min-width: 240px;}

@media screen and (max-width: $max-media-width){
  .center-container{
    width: 100%;
    padding:0 $mpk-padding-N
  }
}