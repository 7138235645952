
.company-img{
    width: 180px;
    height: 180px;
    &.size-small{
        width: 160px;
        height: 160px;
    }
    .default{
        position: relative;
        width: 100%;
        height: 100%;
        background: #5d6570;
        .md-icon{
            font-size: 72px;
            color: #aaa;
        }
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;    
    }
}